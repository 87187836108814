<template>

   <!-------slider--section------>
   <section class="banner type_one"  style="height:600px; overflow:hidden; background:url(assets/image/main-slider/slider.jpg); background-position: left !important; background-size: fit !important;">
      <div class="container">
         <div class="row" style="zoom:90% !important">
            <div class="col-lg-6 d-flex  pr_0" style="background-color:rgba(255, 255, 255 , 0.7); background-position: center; background-size: cover; border-top-right-radius: 40px; border-bottom-left-radius: 40px;">
               <div class="content_outer" >
                  <div class="inner_box mt-2 mb-2 ml-4 mr-4 ">
                     <!-- <h6 class="" data--delay="100ms" data--duration="1500ms">You are not feeling well?</h6> -->
                     <h1 class="" >Assesment of Covid 19 Response in Eastern, Central and West Africa </h1>
                     <p class="" >Assessment of the COVID-19 Response in Eastern, Central and Western Africa project conducted in DRC, Ghana, Nigeria, Senegal and Uganda</p>
                  </div>

                  <div class="link_box " >
                     <router-link :to="{name:'moreaboutproject'}" class="theme_btn tp_one">More About the Project</router-link>
                     <router-link :to="{name:'aboutcovid'}"  class="theme_btn tp_border"><span class="linearicons-arrow-right"></span>   About COVID-19</router-link>
                  </div>
                  
               </div>
            </div>
            <div class="col-lg-6">
               <div class="image_box d-none d-sm-block" style="top:-50px; left:-50px;width:90%; height:750px" >
                  <!-- <img style="width:90%; height:750px" src="assets/image/main-slider/countryimage.png"  alt="img" /> -->
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-------slider--section-end----->
   <!-----------counter--------------->
   <!-- <section class="counter type_two  fadeIn d-none d-sm-block"  >
      <div class="container" >
         <div class="counter_outer_box" >
            <div class="row">
             
               <div class="col-lg-4 col-md-12">
               
                  <div class="heading tp_one">
                     <h6>Worldwide Tracker</h6>
                     <h1 >Pandemic Statistics</h1>
                     <p>Last updated: April, 2022, 14:49 GMT</p>
                  </div>
             
               </div>
               <div class="col-lg-8 col-md-12 d-flex">
    
                  <div class="counter_box_outer">
                     <div class="counter_box type_two one">
                        <div class="image_box">
                           <img src="assets/image/svg/fun-1.svg" class="img-fluid" alt="svg_iage" />
                        </div>
                        <div class="text_box">
                           <h2><span class="odometer " data-count="504"></span></h2>
                           <h6 >Cases in Millions</h6>
                        </div>
                     </div>
                     <div class="counter_box type_two two">
                        <div class="image_box">
                           <img src="assets/image/svg/fun-2.svg" class="img-fluid" alt="svg_iage" />
                        </div>
                        <div class="text_box">
                           <h2><span class="odometer " data-count="6.2"></span></h2>
                           <h6 >Deaths in Millions</h6>
                        </div>
                     </div>
                     <div class="counter_box type_two three">
                        <div class="image_box">
                           <img src="assets/image/svg/fun-3.svg" class="img-fluid" alt="svg_iage" />
                        </div>
                        <div class="text_box">
                           <h2><span class="odometer " data-count="448"></span></h2>
                           <h6 >Recovered in Millions</h6>
                        </div>
                     </div>
                  </div>
                 
               </div>
             
            </div>
         </div>
      </div>
   </section> -->
   <!-----------counter-end-------------->
   <!-----------about--------------->
   <section class="about type_two pdt_50   fadeIn"  >
      <div class="container">
         <div class="row">
            <!---------row----------->
            <div class="col-lg-6 col-md-12 d-flex">
               <!---------col----------->
               <div class="about_content p-3">
                  <div class="heading tp_one " >
                     <!-- style="background-color:#00A551; background-position: center; background-size: cover; border-top-right-radius: 40px; border-bottom-left-radius: 40px;" -->
                     <h1 class="p-2 ">ABOUT US</h1>
                  </div>
                  <p style="text-align:justify">
                  The COVID-19 pandemic is an unprecedented global health crisis that has affected all countries and territories in the world, causing more than three million deaths. In response to the COVID-19 pandemic toll in Africa, several countries mobilized significant financial, human, logistical and technical support to strengthen pre-existing public health response capacities of their health systems. Many countries instituted non-pharmaceutical interventions with varying intensities and coverage such as the closure of education and religious institutions, banning of public transport and overnight curfew.</p>
                  
                  <p class="last"> <router-link :to="{name:'moreaboutproject'}" class="theme_btn tp_one">Read More About the Project</router-link>
                  </p>
             
               </div>
               <!---------col-end---------->
            </div>
            <div class="col-lg-6 col-md-12" >
         
               <div class="image_box one d-none d-sm-block" style="margin-top:60px">
                  <img src="assets/image/resources/about-2-1.png" class="img-fluid" alt="img" />
               </div>
               <!---------col-end---------->
            </div>
            <!----------row-end---------->
         </div>
   
      </div>
   </section>
   <!-----------counter-end-------------->


   <!-----------faq--------------->
   <!-- <section class="faq type_one faq_tabs pdt_50  pdb_50" >
      <div class="container">
         <div class="row p-2">
         <div class="col-lg-12">
               <div class="text_box">
                  <div class="heading tp_one">
                     <h1>Message from the Principal Investigator</h1>
                  </div>
               </div>
         </div>             <div class="col-lg-4"  >
                              <div style="background-image:url(assets/image/resources/dr-rhoda-wanyenze-sph-01.jpg); background-position: center; background-size: cover; border-top-right-radius: 40px; border-bottom-left-radius: 40px; height:400px">
                              
                              </div>
                              <h6 class="text-center p-2">Prof. Rhoda Wanyenze - Dean of MakSPH</h6>
                           </div>

                           <div class="col-lg-8">
                              <div class="text_box">
                             
                                 <p style="text-align:justify">During this global crisis, which has evolved at an unprecedented pace, and whose duration we cannot
foresee, many of us are naturally concerned for our loved ones, particularly those who are the most
vulnerable, because of age or health related factors. Crises like the COVID-19 pandemic have undermined and undercut essential health services across the
globe and in many countries, the healthcare system got overwhelmed.</p>

<p style="text-align:justify">The emergence of the COVID-19 pandemic has brought to the fore the need for institutions to share
expertise, and work collaboratively across African countries and regions as well as globally to effectively
respond to epidemic challenges while balancing these with persisting systems deficiencies. Regarding COVID-19 response interventions, the COVID-19 assessment project draws its line of focus
towards key response levers like testing and surveillance, health systems resilience, non-pharmaceutical
interventions, gender and socio-economic vulnerabilities, vaccine preparedness, and epidemic control
among others.</p>


                              </div>
                           </div>
                          

                           <div class="col-lg-12" >
                           <p style="text-align:justify"> To foster cross country learning and evidence synthesis, our project is in partnership with several universities from the eastern, central, and western Africa including the University of Cheikh Anta Diop
Dakar, Senegal; the University of Ibadan, Nigeria; the University of Kinshasa, DRC; and the University of
Ghana. This important work would not be possible without funding from the Bill and Melinda Gates Foundation
as well as the Gate Ventures. We plan to continue hosting discussion platforms and tracking the COVID-
19 throughout the COVID-19 pandemic in order to learn and foster learning health systems. We hope to build capabilities that will play a key role in developing modelling capacities in Africa to help monitor and enhance efforts and control measures during all stages of future epidemics. We are confident that by working together with Ministries of Health, Development partners, Health workers and communities, we will overcome this crisis do so in a manner that safeguards the safety and
protection of our personnel, and that of the people we serve. It is a great honor and privilege for me to lead and serve together with all our partners on this project.</p>
                           </div>
                        </div>
         
      </div>
   </section> -->
   <!-----------faq-end-------------->
   <!-----------blog--------------->
   <section class="blog type_two pdt_50  pdb_50" style="background-color:rgba(244, 233, 203, 0.3)">
      <div class="container">
         <div class="row">
            <!---------row----------->
            <div class="col-lg-12 col-md-12">
               <!---------col----------->
               <div class="heading text-center tp_one">
                  <!-- <h6>Recent from Updates</h6> -->
                  <h1>LATEST NEWS & BLOGS</h1>
                  <p>Updates, news, articles and blog posts from the Assessment of the COVID-19 Response in Eastern, Central and Western Africa project</p>
               </div>
               <!---------col-end---------->
            </div>
            <!----------row-end---------->
         </div>
         <div class="row">
            <!---------row----------->
            <div class="col-lg-12 col-md-12 padding_zero">
               <!---------col----------->
               <div class="owl-carousel three_items">

               <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="@/assets/image/news/covidvac2.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="@/assets/image/news/covidvac2.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 style="height:180px; overflow:hidden " ><a href="https://theconversation.com/ghanas-covid-19-vaccine-rollout-is-struggling-to-keep-up-with-its-great-start-159579" target="_blank">Ghana’s COVID-19 vaccine rollout is struggling to keep up with its great start</a></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>1st May 2021</li>
                           <li><span class="linearicons-user"></span>Nana Kofi Quakyi</li>
                        </ul>
                        <p>Ghana became the first country globally to receive a vaccine shipment from the COVAX facility – a global initiative that’s trying to ensure equitable access to COVID-19 vaccines – when 600,000 Oxford-AstraZeneca vaccine doses were delivered in February 2021. </p>
                        <a href="https://theconversation.com/ghanas-covid-19-vaccine-rollout-is-struggling-to-keep-up-with-its-great-start-159579" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                     </div>
                  </div>


                   <!---------blog_box end----------->

                  <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="@/assets/image/news/covidvaccine.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="@/assets/image/news/covidvaccine.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 style="height:180px; overflow:hidden " ><a href="https://theconversation.com/ghanas-covid-19-vaccine-rollout-is-struggling-to-keep-up-with-its-great-start-159579" target="_blank">Ghana’s COVID-19 vaccine rollout is struggling to keep up with its great start</a></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>1st May 2021</li>
                           <li><span class="linearicons-user"></span>Nana Kofi Quakyi</li>
                        </ul>
                        <p>Ghana became the first country globally to receive a vaccine shipment from the COVAX facility – a global initiative that’s trying to ensure equitable access to COVID-19 vaccines – when 600,000 Oxford-AstraZeneca vaccine doses were delivered in February 2021. </p>
                        <a href="https://theconversation.com/ghanas-covid-19-vaccine-rollout-is-struggling-to-keep-up-with-its-great-start-159579" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                     </div>
                  </div>



                  <!---------blog_box end----------->

                  <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="@/assets/image/news/nafdac.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="@/assets/image/news/nafdac.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 style="height:180px; overflow:hidden " ><a href="https://theconversation.com/ghana-faces-hurdles-to-achieve-targets-set-for-covid-19-vaccine-rollout-155075" target="_blank">COVID-19: NAFDAC approves two herbal drugs for clinical trial</a></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>11th Jan 2022</li>
                           <li><span class="linearicons-user"></span>Nan</li>
                        </ul>
                        <p>The National Agency for Food and Drug Administration and Control (NAFDAC) says it has approved two herbal drugs to undergo clinical trials for the treatment of COVID-19.A statement on Sunday by the agency Resident Media Consultant, Mr Olusayo Akintola, however, denounced the claim of approval of IHP Detox Tea for the treatment of COVID-19 patients.</p>
                        <a href="https://theconversation.com/ghana-faces-hurdles-to-achieve-targets-set-for-covid-19-vaccine-rollout-155075" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                     </div>
                  </div>

          
                  <!---------blog_box----------->
                  <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="assets/image/news/blogpartnership.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="assets/image/news/blogpartnership.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 style="height:180px; overflow:hidden " ><router-link :to="{name:'maksphleadsapartnership'}">MakSPH leads a partnership of five African universities to
generate evidence to support the COVID-19 response</router-link></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>17 April 2020</li>
                          <li><span class="linearicons-user"></span>Davidson Ndyabahika</li>
                        </ul>
                        <p>MakSPH is in the second year of the assessment of the COVID-19 response in Africa in partnership with several universities including University of Cheikh Anta Diop Dakar, Senegal; University of Ibadan, Nigeria; University of Kinshasa, DRC; and the University of Ghana.</p>
                        <router-link :to="{name:'maksphleadsapartnership'}" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></router-link>
                     </div>
                  </div>
                  <!---------blog_box end----------->


                  <!---------blog_box----------->
                  <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="@/assets/image/news/nitalauch.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="@/assets/image/news/nitalauch.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 style="height:180px; overflow:hidden " ><router-link :to="{name:'nitaulauch'}">MTN, NITA-U launch new app for tracking COVID-19 patients under home based care</router-link></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>20 Mar 2021</li>
                           <li><span class="linearicons-user"></span>Mulengera</li>
                        </ul>
                        <p>MTN Uganda in partnership with the National Information Technology Authority NITA-Uganda (NITA-U) on Friday March 19, 2021 unveiled a new app that will be used for geo-fencing and tracking COVID-19 patients that are under home based care.</p>
                        <router-link :to="{name:'nitaulauch'}" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></router-link>
                     </div>
                  </div>
                  <!---------blog_box end----------->


               <!---------blog_box----------->
                  <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="@/assets/image/news/storyghana.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="@/assets/image/news/storyghana.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 style="height:180px; overflow:hidden " ><router-link :to="{name:'wvghanahelp'}">World Vision Supports Ghana Health Service in response to COVID-19</router-link></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>25 Mar 2020</li>
                           <li><span class="linearicons-user"></span>World Vision Ghana</li>
                        </ul>
                        <p>World Vision Ghana has provided about 40,000 Personal Protective Equipment such as nose masks, coveralls, goggles, gloves, disposable towels for hand drying, veronica buckets, antibacterial liquid hand wash, large plastic basins and foot operated plastic waste bins.</p>
                        <router-link :to="{name:'wvghanahelp'}" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></router-link>
                     </div>
                  </div>


                  <!---------blog_box end----------->

                  <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="@/assets/image/news/ghananewround.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="@/assets/image/news/ghananewround.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 style="height:180px; overflow:hidden " ><a href="http://www.xinhuanet.com/english/2021-08/17/c_1310130940_2.htm" target="_blank">Ghana starts new round of COVID-19 vaccination</a></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>25 April 2021</li>
                           <li><span class="linearicons-user"></span>Xinhua</li>
                        </ul>
                        <p>A nurse gives a man an injection of the COVID-19 vaccine at a vaccination site in Accra, Ghana, on Aug. 16, 2021. Ghana on Monday began a new round of COVID-19 vaccination with 177,700 single-dose Johnson & Johnson vaccines to be administered, said health authorities.</p>
                        <a href="http://www.xinhuanet.com/english/2021-08/17/c_1310130940_2.htm" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                     </div>
                  </div>


                  <!---------blog_box end----------->

                  <div class="blog_box type_one  fadeInUp">
                     <div class="image_box">
                        <img src="@/assets/image/news/ghanatest.jpg" style="height:220px; width:100%; object-size:cover; object-position:center" class="img-fluid" alt="img" />
                        <div class="overlay ">
                           <a href="@/assets/image/news/ghanatest.jpg" data-fancybox="gallery " data-caption=" ">
                           <span class="linearicons-picture2"></span>
                           </a>
                        </div>
                     </div>
                     <div class="content_box">
                        <h2 style="height:180px; overflow:hidden " ><a href="https://theconversation.com/covid-19-has-left-ghanas-healthcare-workers-stressed-but-simple-things-can-help-157907" target="_blank">COVID-19 has left Ghana’s healthcare workers stressed – but simple things can help</a></h2>
                        <ul>
                           <li><span class="linearicons-calendar-full"></span>30 April 2021</li>
                           <li><span class="linearicons-user"></span>Patience Afulani</li>
                        </ul>
                        <p>Stress and burnout among healthcare workers is a global crisis that predates the COVID-19 pandemic. Studies done before the pandemic showed that many healthcare workers around the world were experiencing high levels of stress and burnout. But the pandemic has exacerbated this – particularly in low resource settings.</p>
                        <a href="https://theconversation.com/covid-19-has-left-ghanas-healthcare-workers-stressed-but-simple-things-can-help-157907" target="_blank" class="read_more tp_one"> Read more  <span class="linearicons-arrow-right"></span></a>
                     </div>
                  </div>


             <!---------blog_box end----------->

                  


                  <!---------blog_box end----------->
               </div>
               <!---------col-end---------->
            </div>
            <!----------row-end---------->
         </div>
      </div>
   </section>
   <!-----------blog-end-------------->
   <!-----------partners--------------->
   <section class="partners type_one pdt_30  pdb_70" >
   <div class="container">
      <div class="row">
            <!---------row----------->
            <div class="col-lg-12 col-md-12">
               <!---------col----------->
               <div class="heading text-center tp_one">
                  <!-- <h6>Recent from Updates</h6> -->
                  <h1>Project Partners</h1>
                 
               </div>
               <!---------col-end---------->
            </div>
            <!----------row-end---------->
         </div>
   </div>
      <div class="container">
      
         <div class="row">
            <!---------row----------->
            <div class="col-lg-12 col-md-12">
               <!---------col----------->
               <div class="owl-carousel four_items">
                  <div class="partners_logo type_one">
                     <img src="@/assets/image/clients/c1.jpg" class="img-fluid" style="width:150px !important; height:150px !important; object-position:fit; object-size:cover" alt="partners" />
                  </div>
                  <div class="partners_logo type_one">
                     <img src="@/assets/image/clients/c2.jpg" class="img-fluid" style="width:150px !important; height:150px !important; object-position:fit; object-size:cover" alt="partners" />
                  </div>

                  <div class="partners_logo type_one">
                     <img src="@/assets/image/clients/c3.jpg" class="img-fluid" style="width:150px !important; height:150px !important; object-position:fit; object-size:cover" alt="partners" />
                  </div>

                  <div class="partners_logo type_one">
                     <img src="@/assets/image/clients/c4.png" class="img-fluid" style="width:150px !important; height:150px !important; object-position:fit; object-size:cover"  alt="partners" />
                  </div>
                  <div class="partners_logo type_one">
                     <img src="@/assets/image/clients/c5.jpg" class="img-fluid" style="width:150px !important; height:150px !important; object-position:fit; object-size:cover"  alt="partners" />
                  </div>

                  <div class="partners_logo type_one">
                     <img src="@/assets/image/clients/govlab.jpg" class="img-fluid" style="width:150px !important; height:150px !important; object-position:fit; object-size:cover" alt="partners" />
                  </div>
               </div>
               <!---------col-end---------->
            </div>
            <!----------row-end---------->
         </div>
      </div>
   </section>
   <!-----------partners-end-------------->


   <!-----------newsletter--------------->
   <section class="newsletter type_one pdt_80  pdb_40">
      <div class="container">
         <div class="row">
            <!---------row----------->
            <div class="col-lg-6 col-md-12">
               <!---------col----------->
               <div class="heading white  tp_one">
                  <h1>Subscribe our newsletter </h1>
                  <p> Join our subscribers list to get latest news and updates about COVID-19<br class="md_display_none"> delivered directly in your inbox.</p>
               </div>
               <!---------col-end---------->
            </div>
            <div class="col-lg-6 col-md-12">
               <!---------col----------->
               <div class="subscribe_box tp_one">
                  <form>
                     <div class="form_group">
                        <input type="text" placeholder="Type here your question...">
                        <button class="search_btn" type="submit">Subscribe</button>
                     </div>
                     <div class="form_group mg_top check_box">
                        <input name="checkbox" type="checkbox" id="test3" required="required">
                        <label for="test3">I accept the <a href="#">Privacy Policy.</a></label>
                     </div>
                  </form>
               </div>
               <!---------col-end---------->
            </div>
            <!----------row-end---------->
         </div>
      </div>
   </section>
   <!-----------newsletter-end-------------->
 

</template>